import { graphql } from "gatsby";
import React from "react";

export default function Location({ data, state }) {
  return <></>;
}

export const query = graphql`
  query {
    queryLocation: allSanityLocation(filter: { language: { eq: "de" } }) {
      nodes {
        carousselType
        place
        _rawText
        title
        id
        images {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
              base64
            }
          }
        }
      }
    }
    seo: allSanitySeo(
      filter: { title: { eq: "Location" }, language: { eq: "de" } }
    ) {
      nodes {
        id
        image {
          asset {
            fluid(maxWidth: 700) {
              ...GatsbySanityImageFluid
              base64
            }
          }
        }
        title
        titlePage
        description
      }
    }
    queryShowHidePlaces: allSanityShowPlacesProgramm {
      nodes {
        klubShowProgramm
        klubShowLocation
        klubShowMieten
        saalShow
        wiengartenShow
        biergartenShow
      }
    }
  }
`;
